import { FC, MouseEvent, PropsWithChildren } from "react";
import joinClassNames from "classnames";

import ArrowLeftIcon from "icons/arrow-left.svg?react";

import Button from "basics/button";

import Header from "../header";

import classes from "./styles/classes.module.scss";

interface Props extends PropsWithChildren {
  isClosable?: boolean;
  classNames?: Partial<{
    step: string;
    closeButton: string;
    backButton: string;
  }>;
  className?: string;
  onBack?(e: MouseEvent<HTMLButtonElement>): void;
  onClose?: VoidFunction;
}

const StepHeader: FC<Props> = ({
  children,
  classNames = {},
  className,
  isClosable,
  onBack,
  onClose,
}) => (
  <Header
    isClosable={isClosable}
    classNames={{ closeButton: classNames.closeButton }}
    className={className}
    onClose={onClose}
  >
    {onBack ? (
      <Button
        size="sm"
        themeName="ghost"
        isIconOnly
        onClick={onBack}
        className={classNames.backButton}
      >
        <ArrowLeftIcon className={classes.icon} />
      </Button>
    ) : (
      <div
        className={joinClassNames(
          classes.backButtonHolder,
          classNames.backButton,
        )}
      />
    )}
    <p className={joinClassNames(classes.stepText, classNames.step)}>
      {children}
    </p>
  </Header>
);

export default StepHeader;
