import { useContext, useEffect } from "react";
import { UNSAFE_DataRouterContext } from "react-router-dom";
import { Location } from "@remix-run/react";

const useChangePathname = (
  callback: (current: Location, previous: Location | null) => void,
  isEqualLocation: (current: Location, previous: Location | null) => boolean = (
    current,
    previous,
  ) => previous?.pathname !== current.pathname,
) => {
  const context = useContext(UNSAFE_DataRouterContext);

  useEffect(() => {
    let memoLocation = context.router.state.location;

    return context.router.subscribe(({ location }) => {
      if (isEqualLocation(location, memoLocation)) {
        callback(location, memoLocation);
      }

      memoLocation = location;
    });
  }, []);
};

export default useChangePathname;
