import { FC, PropsWithChildren } from "react";
import joinClassNames from "classnames";
import { isString } from "lodash";

import CloseIcon from "icons/close.svg?react";

import Button from "basics/button";

import { useDialog } from "components/dialog";

import classes from "./styles/classes.module.scss";

interface Props extends PropsWithChildren {
  isClosable?: boolean;
  classNames?: Partial<{ title: string; closeButton: string }>;
  className?: string;
  onClose?: VoidFunction;
}

const Header: FC<Props> = ({
  children,
  className,
  classNames = {},
  isClosable = true,
  onClose,
}) => {
  const { resolve } = useDialog();

  return (
    <div className={joinClassNames(classes.wrapper, className)}>
      {isString(children) ? (
        <h2 className={joinClassNames(classes.title, classNames.title)}>
          {children}
        </h2>
      ) : (
        children
      )}
      {isClosable && (
        <Button
          themeName="ghost"
          size="sm"
          isIconOnly
          onClick={() => {
            resolve(null);
            onClose?.();
          }}
          className={joinClassNames(classes.closeButton, classNames.closeButton)}
        >
          <CloseIcon className={classes.icon} />
        </Button>
      )}
    </div>
  );
};

export default Header;
