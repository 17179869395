import { PropsWithChildren, useEffect } from "react";
import joinClassNames from "classnames";

import useChangePathname from "hooks/use-change-pathname";

import Header from "./components/header";
import StepHeader from "./components/step-header";
import Overlay from "../components/overlay";

import { useDialog, useClickOutside } from "../duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props extends Required<PropsWithChildren> {
  isClosable?: boolean;
  onClosed?: VoidFunction;
  onClose?: VoidFunction;
  className?: string;
  classNames?: Partial<{ overlay: string; closeButton: string }>;
}

const Modal = ({
  children,
  onClosed,
  onClose,
  className,
  classNames = {},
}: Props) => {
  const { resolve, type } = useDialog();

  const closeModal = () => {
    resolve(null);
    onClose?.();
  };

  useEffect(() => onClosed, []);

  useChangePathname(() => type !== "inline" && resolve(null));

  const { contentRef, overlayProps } = useClickOutside(closeModal);

  return (
    <Overlay className={classNames.overlay} {...overlayProps}>
      <div
        role="dialog"
        aria-modal="true"
        className={joinClassNames(classes.wrapper, className)}
        ref={contentRef}
      >
        {children}
      </div>
    </Overlay>
  );
};

Modal.Header = Header;
Modal.StepHeader = StepHeader;

export default Modal;
