import { FC, HTMLAttributes } from "react";
import joinClassNames from "classnames";
import FocusLock from "react-focus-lock";

import classes from "./styles/classes.module.scss";

const Overlay: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <FocusLock>
    <div
      tabIndex={-1}
      className={joinClassNames(classes.wrapper, className)}
      {...props}
    >
      {children}
    </div>
  </FocusLock>
);

export default Overlay;
